import request from "../infra/request"
import configEnv from '../utilities/config.utility';
import { setStorage } from "../storage";
import { FormatString } from "../utilities/validations.utility";

const postLoginUserService = async (payload: any) => {
  try {
    const { data, statusText } = await request.post(FormatString(configEnv.URL_loginUser, [payload.id]), payload);
    if (statusText) return { statusText };
    setStorage('userStorage', data);
    setStorage('userSecretStorage', payload);
    return data;

  } catch (e) {
    return { statusText: e }
  }
}

const postRegisterUserService = async (payload: any) => {
  try {

    const { data, statusText } = await request.post(configEnv.URL_registerUser, payload);

    if (statusText) return { statusText }
    setStorage('userStorage', data);
    return data;

  } catch (e) {
    return { statusText: e }
  }
}

export {
  postLoginUserService,
  postRegisterUserService,
}