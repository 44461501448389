import ImageCard from "./bcard.png";
import ImageCardBack from "./bcardBack.png";
import ImageCardInfo from "./bcardInfo.png";
import ImageLinkedInIVAN from "./linkedin-ivan.png";
import ImageLinkedInMARIANO from "./linkedin-mariano.png";
import ImageTwitterIVAN from "./tw-ivan.png";
import ImageTwitterMARIANO from "./tw-mariano.png";

type Map = {
  [key: string]: string | undefined
}

const SvgImage: Map = {
  "default": "",
  "image-card": ImageCard,
  "image-card-back": ImageCardBack,
  "image-card-info": ImageCardInfo,
  "linkedin-ivan": ImageLinkedInIVAN,
  "linkedin-mariano": ImageLinkedInMARIANO,
  "tw-ivan": ImageTwitterIVAN,
  "tw-mariano": ImageTwitterMARIANO
};

export default SvgImage;

