import styles from "./baseModal.module.scss";
import BaseIcon from '../../../../../baseComponents/BaseIcons/index';
import BaseImage from "../../../../../baseComponents/BaseImage";
import BaseRange from "../../../../../baseComponents/BaseRange";
import { useState, useEffect } from 'react'
import { useMutation } from "react-query";
import { getDepositQuote } from "../../../services/crypto.service";

type TAlert = {
  data?: any,
  isVisible: boolean,
  showModal: any,
}

const ModalInstructions = ({ isVisible = false, showModal, data }: TAlert) => {
  const [rangeVal, setRangeVal] = useState([50]);
  const [quote, setQuote] = useState('...');
  const [fx, setFX] = useState('');
  const [loadingQuote, setLoadingQuote] = useState(false);

  const copyClipboard = (value: string) => {
    navigator.clipboard.writeText(value).then(() => { });
  }

  const onSetRange = (value: any) => {
    setRangeVal(value);
    setLoadingQuote(true);
    getQuote.mutate(rangeVal[0]);
  }

  const onChangeRange = (value: any) => {
    setRangeVal(value);
    setLoadingQuote(true);
    setFX('');
  }

  useEffect(() => {
    getQuote.mutate(rangeVal[0]);
  }, [])

  useEffect(() => {
    if (rangeVal[0] && !isNaN(rangeVal[0]) && rangeVal[0] > 0) {
      const sQuote = Number.parseFloat(quote);
      if (!isNaN(sQuote) && sQuote > 0) {
        setFX((sQuote / rangeVal[0]).toFixed(2));
      }
      else {
        setFX('');
      }
    }
    else {
      setFX('');
    }

  }, [quote]);

  const getQuote = useMutation("setQuote", getDepositQuote, {
    onSuccess: (value: any) => {
      setQuote(value);
      setLoadingQuote(false);
    },
    onError: (error, variables, context) => {
      setQuote('plase try again...');
      setLoadingQuote(false);
    }
  });

  return (
    <>
      <div className={`${isVisible ? styles.visible : styles.noVisible} ${styles.modal}`}>
        <div className={styles.modal__content}>
          <div className={styles.modal__header}>
            How to top up the card<div className={styles.modal__header__close}><BaseIcon icon={`icon-close`} onClick={() => showModal(false)} /></div>
          </div>
          <div className={styles.modal__body}>
            Get <u><a href='https://stellar.expert/explorer/public/asset/ARS-GCYE7C77EB5AWAA25R5XMWNI2EDOKTTFTTPZKM2SR5DI4B4WFD52DARS-1'>ARS tokens</a></u> at the DEX, <br />and send them to the following address.<br /><br />

            <div className={styles.coin__field}>
              <span>ADDRESS:</span>
              <strong>GACARDL4 . . . . . . . . KW7OR32P</strong>
              <div className={styles.icon__copy}>
                <BaseIcon icon={`icon-copy`} onClick={() => copyClipboard("GACARDL4PQKU4BGHPXMBIHYKHLWZHLJWL7VOIK6EVCTLKR3NKW7OR32P")} />
              </div>
            </div>
            <div className={styles.coin__field}>
              <span>ALIAS:</span>
              <strong>{data}*anclap.com</strong>
              <div className={styles.icon__copy}>
                <BaseIcon icon={`icon-copy`} onClick={() => copyClipboard(`${data}*anclap.com`)} />
              </div>
            </div>
            <div className={styles.coin__fieldH}>
              <span>MEMO TEXT:</span>
              <strong> {data}</strong>
              <div className={styles.icon__copy}>
                <BaseIcon icon={`icon-copy`} onClick={() => copyClipboard(data)} />
              </div>
            </div>
            <div className={styles.modal__qr}><div style={{ 'width': '100%', 'marginRight': '10px' }}>
              Or scan the QR using <br /><b>Lobstr</b> or <b>Beans Wallet.</b><br />
              Do not forget to include the <b>MEMO:</b> <div className={styles.memo} onClick={() => copyClipboard(data)}> {data}</div></div>
              <BaseImage image='icon-qr-kinetic' width={'100'} height={'100'} />
            </div>
            <hr />
            <div className={styles.modal__simulate}><b>Do you want to send USDC <br />instead of ARS? No prob!</b><br /><small> Let's roughly estimate how many ARS you would get!</small>
              <BaseRange stateValues={rangeVal} onFinalChange={onSetRange} onChange={onChangeRange}></BaseRange>
            </div>
            <output style={{ marginTop: "10px", paddingBottom: "10px", 'width': '100%', display: "flex", fontSize: 'larger' }} id="output">
              <div style={{ 'width': '45%', textAlign: 'right', marginRight: '5px' }}><b>{rangeVal[0]?.toFixed(1)}</b> <small>USDC </small></div> = <div style={{ 'width': '55%', textAlign: 'left', marginLeft: '5px' }}>{loadingQuote ? (<><span style={{ color: '#ccc' }}>fetching quote...</span></>) : (<><b>{quote}</b> <small>ARS </small></>)}</div>
            </output>
            <span style={{ marginTop: "5px", paddingBottom: "20px", 'width': '100%', fontSize: 'small' }}>{fx ? (<>Exchange Rate: <b>{fx}</b> ARS/USDC</>) : ""}</span>
          </div>

        </div>
      </div >

    </>
  );
};


export default ModalInstructions;