import request from "../../../infra/request"
import { getStorage } from "../../../storage";
import configEnv from '../../../utilities/config.utility';

export const getCryptoAddresses = async () => {
  try {

    const state = getStorage('userStorage');
    const headers = {
      authorization: `Bearer ${state?.token}`
    }

    const { data, statusText } = await request.get(configEnv.URL_getCryptoAddresses, headers);

    if (statusText) return { statusText };
    return data.body;

  } catch (e) {
    return { statusText: e }
  }
}

export const getExchange = async (amount: any) => {
  try {
    let amountOut = "-";
    if (!amount || amount === "0") return "0";
    const sUrl = `https://horizon.stellar.org/paths/strict-send?source_amount=${amount}&source_asset_type=credit_alphanum4&source_asset_code=ARS&source_asset_issuer=GCYE7C77EB5AWAA25R5XMWNI2EDOKTTFTTPZKM2SR5DI4B4WFD52DARS&destination_assets=USDC:GA5ZSEJYB37JRC5AVCIA5MOP4RHTM335X2KGX3IHOJAPP5RE34K4KZVN`
    const { data, statusText } = await request.get(sUrl);
    if (data?._embedded?.records?.length > 0) {
      amountOut = parseFloat(data._embedded.records[0].destination_amount).toFixed(2);
    }
    if (statusText) return { statusText };
    return amountOut;

  } catch (e) {
    return { statusText: e }
  }
}

export const getDepositQuote = async (amount: number = 10) => {
  try {
    let amountOut = "";
    if (amount === 0) return (0).toFixed(2);
    const sUrl = `https://horizon.stellar.org/paths/strict-send?source_amount=${amount}&source_asset_type=credit_alphanum4&source_asset_code=USDC&source_asset_issuer=GA5ZSEJYB37JRC5AVCIA5MOP4RHTM335X2KGX3IHOJAPP5RE34K4KZVN&destination_assets=ARS:GCYE7C77EB5AWAA25R5XMWNI2EDOKTTFTTPZKM2SR5DI4B4WFD52DARS`;
    const { data, statusText } = await request.get(sUrl);
    if (data?._embedded?.records?.length > 0) {
      amountOut = parseFloat(data._embedded.records[0].destination_amount).toFixed(2);
    }
    if (statusText) return { statusText };
    return amountOut;

  } catch (e) {
    return { statusText: e }
  }
}