import React, { useState } from "react";
import ReactCardFlip from "react-card-flip";
import styles from "./baseFlip.module.scss";
import BaseImage from "../BaseImage";

type TBaseData = {
  ExpiryDate?: string
  Id?: string
  Pin?: string
}

type TFlipParams = {
  secret?: string
  data?: TBaseData
}

const BaseFlip: React.FC<TFlipParams> = ({
  secret = "",
  data = null
}) => {
  const [flip, setFlip] = useState(false);

  return (<ReactCardFlip isFlipped={flip} flipDirection="horizontal">
    <div className={styles.cardImageDiv} onClick={() => setFlip(!flip)}>
      <BaseImage image='image-card' width={'100%'} height={'100%'} />
      <div className={`${styles.card_number} font-big`}>
        <div className={styles.cardImageDiv_moreinfo}>Show Info</div> ANCLAP<br />
        **** **** {secret.substring(0, 4)} {secret.substring(4, 8)} <br /><br />
      </div>
    </div>
    <div className={styles.cardImageDivBack} onClick={() => setFlip(!flip)}>
      <BaseImage image='image-card-back' width={'100%'} height={'100%'} />
      <div className={`${styles.card_number} font-big`}>
        <div className={styles.cardImageDivBack_moreinfo}>Hide Info</div> ANCLAP<br />
        **** **** {secret.substring(0, 4)} {secret.substring(4, 8)} <br />
        <span>EXP DATE: </span> {data?.ExpiryDate?.substring(5, 7)}/{data?.ExpiryDate?.substring(2, 4)} &nbsp; <span>ATM PIN: </span> {data?.Pin}
      </div>
    </div>
  </ReactCardFlip>);

};

export default BaseFlip;
