import React from "react";
import styles from './styles/baseToggle.module.scss'

/*
Toggle Switch Component
Note: id, checked and onChange are required for ToggleSwitch component to function. The props name, small, disabled
and optionLabels are optional.
Usage: <ToggleSwitch id="id" checked={value} onChange={checked => setValue(checked)}} />
*/

const ToggleSwitch = ({
  id,
  name,
  checked = false,
  onChange,
  optionLabels = ["", ""],
  small = false,
  disabled = false
}: TToggleSwitch) => {
  function handleKeyPress(e: any) {
    if (e.keyCode !== 32) return;

    e.preventDefault();
    onChange(!checked);
  }

  return (
    <div className={`${styles.toggle_switch} ${small ? styles.small_switch : ""}`}>
      <input
        type="checkbox"
        name={name}
        className={styles.toggle_switch_checkbox}
        id={id}
        checked={checked}
        onChange={(e) => onChange(e.target.checked)}
        disabled={disabled}
      />
      {
        id ? (
          <label
            className={styles.toggle_switch_label}
            tabIndex={disabled ? -1 : 1}
            onKeyDown={(e) => handleKeyPress(e)}
            htmlFor={id}
          >
            <span
              className={`${styles.toggle_switch_inner} ${disabled ? styles.toggle_switch_disabled : ""}`}
              data-yes={optionLabels[0]}
              data-no={optionLabels[1]}
              tabIndex={-1}
            />
            <span
              className={`${styles.toggle_switch_switch} ${disabled ? styles.toggle_switch_disabledSwitch : ""}`}
              tabIndex={-1}
            />
          </label>
        ) : null
      }
    </div >
  );
};

type TToggleSwitch = {
  id: string,
  checked: boolean,
  onChange: any,
  name?: string,
  optionLabels?: any,
  small?: boolean,
  disabled?: boolean
};


export default ToggleSwitch;
