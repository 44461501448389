import { FC, useState } from 'react'
import LayoutDefault from '../../../../adapters/layouts/default/index';
import BaseButton from '../../../../baseComponents/BaseButton';
import BaseInput from '../../../../baseComponents/BaseInput';
import RecoverPasswordSuccess from './Component/RecoverSuccess';

import styles from './styles/recover.module.scss';

const RecoverPassword: FC = () => {
  const [show, setShow] = useState(false);

  const [recover, setRecover] = useState(false);

  return (
    <LayoutDefault>
      {!recover
        ? (
          <div>
            <div className='title mb-1'>Crea una nueva contraseña</div>
            <div className='subtitle mb-4'>Debe tener al menos 8 caracteres y una mayúscula</div>

            <div className={styles.input}>
              <BaseInput type={`${show ? 'text' : 'password'}`} placeholder='Ingresa tu nueva contraseña' icon={`${show ? 'icon-hide-password' : 'icon-show-password'}`} iconEvent={() => setShow(!show)} />
            </div>

            <div className={styles.input}>
              <BaseInput type={`${show ? 'text' : 'password'}`} placeholder='Vuelve a ingresar la nueva contraseña' />
            </div>

            <BaseButton title='Reestablecer contraseña' onClick={() => setRecover(!recover)} />
          </div>
        ) : (
          <RecoverPasswordSuccess />
        )}



    </LayoutDefault>

  );
}

export default RecoverPassword;