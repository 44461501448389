import styles from "./baseModal.module.scss";
import BaseIcon from '../../../../../baseComponents/BaseIcons/index';
import BaseButton from "../../../../../baseComponents/BaseButton";
import BaseImage from "../../../../../baseComponents/BaseImage";

type TAlert = {
  data?: any,
  isVisible: boolean,
  showModal: any,
}

const copyClipboard = (value: string) => {
  navigator.clipboard.writeText(value)
}

const goToLink = (link: string) => {
  window.open(link);
}

const ModalFeedback = ({ isVisible = false, showModal, data }: TAlert) => {

  return (
    <>
      <div className={`${isVisible ? styles.visible : styles.noVisible} ${styles.modal}`}>
        <div className={styles.modal__content}>
          <div className={`${styles.modal__header} title font-extra-big`}>
            Hey!  <div className={styles.modal__header__close}><BaseIcon icon={`icon-close`} onClick={() => showModal(false)} /></div>
          </div>
          <div className={styles.modal__body}>
            <div className='title mb-4 font-extra-big '>
              <span>YOUR FEEDBACK<br /> WORTH A LOT!!!</span>
            </div>
            <div className='title mb-4 font-medium'>
              <strong style={{ 'maxWidth': '80%', 'display': 'inline-flex', 'marginTop': '5px', 'marginBottom': '20px' }}>Please hit us up on LinkedIn and help us improve, grow, and shape new use cases!</strong>
            </div>
            <div className='title mb-5'>
              <div style={{ 'background': '#eee', 'padding': '20px', 'marginTop': '20px', 'marginBottom': '20px', 'borderRadius': '5px', 'display': 'flex' }}>
                <div style={{ 'width': '50%', 'cursor': 'pointer' }}>
                  <BaseIcon icon='linkedin-ivan' height={292 / 2.2} width={364 / 2.2} style={styles.modal__image} onClick={() => goToLink('https://www.linkedin.com/in/ivanmudryj/')} /> <br />
                  <BaseIcon icon='tw-ivan' height={53 / 2.2} width={340 / 2.2} style={styles.modal__image} onClick={() => goToLink('https://twitter.com/ivanmudryj')} />
                </div>
                <div style={{ 'width': '50%', 'cursor': 'pointer' }}>
                  <BaseIcon icon='linkedin-mariano' height={292 / 2.2} width={364 / 2.2} style={styles.modal__image} onClick={() => goToLink('https://www.linkedin.com/in/mariano-omar-morales/')} /><br />
                  <BaseIcon icon='tw-mariano' height={53 / 2.2} width={340 / 2.2} style={styles.modal__image} onClick={() => goToLink('https://twitter.com/theclabs')} /><br />
                </div>
              </div>
              <div style={{ 'display': 'block', 'maxWidth': '100%' }}><a href="mailto:info@anclap.com">info@anclap.com</a></div>
              <div className='title mt-2 font-regular'>
                <BaseIcon icon='logo-anclap' height={50} width={150} onClick={() => goToLink('https://anclap.com/')} /><br />
                Connecting LATAM!!!
              </div>
            </div>
          </div>

          <div className={styles.modal__footer}>
            <BaseButton title="Close" type="primary" onClick={() => showModal(false)} />
          </div>

        </div>
      </div>

    </>
  );
};


export default ModalFeedback;