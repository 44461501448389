import { FC, useState } from 'react'
import BaseIcon from '../../../../../baseComponents/BaseIcons/index';

import BaseInput from '../../../../../baseComponents/BaseInput/index';
import BaseButton from '../../../../../baseComponents/BaseButton';
import { getStorage } from '../../../../../storage';
import { validateInput } from '../../../../../utilities/validations.utility';

const ValidateToken: FC<{ handlerSubmit: any, isLoading: boolean, errorApi?: string }> = ({ handlerSubmit, isLoading = false, errorApi }) => {

  const [token, setToken] = useState('');
  const [tokenError, setTokenError] = useState('');
  const user = getStorage('userStorage');

  const validateSubmit = async () => {
    const bTokenLen = validateInput(token, 6, null, null, setTokenError);
    if (bTokenLen) handlerSubmit(token);
  }

  return (
    <>
      <div className='justify-center mb-4'>
        <BaseIcon icon='icon-email' width={40} height={40} />
      </div>

      <div className='title mb-4'>Verifica tu email</div>

      <div className='w-100' >
        <BaseInput maxLength={6} placeholder='Ingresa el código que recibiste por email' onChange={(e) => setToken(e)} error={tokenError} />
      </div>

      <div className='error-message m-2'>{errorApi}</div>

      <BaseButton title='Validar' onClick={() => validateSubmit()} isLoading={isLoading} />

      <div className='subtitle mt-4'>
        <small>El código fué enviado a {user?.id}</small>
      </div>
      <div className='subtitle mt-1'>
        <small>Si aún no lo recibiste <br />revisa la casilla de SPAM</small>
      </div>

      <div className='extra-info cursor-pointer'>Reenviar email de verificación</div>
    </>
  );
}

export default ValidateToken;