import { FC, ReactNode } from "react"
import Header from "./components/Header";

import styles from './styles/layout.module.scss';

const LayoutLogin: FC<{ children: ReactNode }> = ({ children }) => {
  return (
    <div className={`${styles.container}`}>
      <Header />
      <div className={styles.boxLogin}>
        {children}
      </div>
    </div>
  )
}

export default LayoutLogin;
