import { FC } from 'react';
import BaseImage from '../../../../../baseComponents/BaseImage';

import styles from './styles/header.module.scss';
import { Link } from 'react-router-dom';

const Header: FC = () => {
  return (
    <Link to={'#'} className={styles.header}>
      <BaseImage image='logo-anclap-w' width={300} />
    </Link>
  );
}

export default Header;