import { Navigate, Outlet } from "react-router-dom";
import { publicRoutes } from "../routes.path";
import { getStorage } from "../../../storage";
import jwt_decode from "jwt-decode";

const validateAuth = () => {
  const user = getStorage("userStorage");
  if (!user?.token) {
    return false;
  }
  else {
    const decoded: any = jwt_decode(user.token);
    if (Date.now() >= decoded.exp * 1000) {
      return false;
    }
  }
  return true;
};

const Guard = () => {
  const validateUser = () => validateAuth();

  return validateUser() ? <Outlet /> : <Navigate replace to={publicRoutes.LOGIN} />;
};


export default Guard;

