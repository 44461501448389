export const setStorage = (nameStorage: string, payload: any) => {
  localStorage.setItem(nameStorage, JSON.stringify(payload));
}

type TStorageUser = {
  "id": string,
  "status": string,
  "token": string
}

type TSecretStorageUser = {
  "id": string,
  "secret": string
}

export type TStoredMov = {
  "Datetime": Date,
  "Type": string,
  "AmountInCardCurrency": number,
  "AmountInDestionationCurrency": number,
  "DestionationCurrency": string,
  "State": string,
  "Description": string
}

type TStoredMovs = {
  "LastQueryDate": Date,
  "Movs": TStoredMov[]
}

export const getStorage = (nameStorage: string): TStorageUser | null => {
  let data = localStorage.getItem(nameStorage);
  if (!data) return null;
  return JSON.parse(data);
}

export const getStoredMovs = (id: string): TStoredMovs | null => {
  let data = localStorage.getItem(`usrStoredMovs_${id}`);
  if (!data) return null;
  return JSON.parse(data);
}
export const cleanStoredMovs = (id: string) => {
  let data = localStorage.getItem(`usrStoredMovs_${id}`);
  if (data) localStorage.removeItem(`usrStoredMovs_${id}`);
}
export const setStoredMovs = (id: string, payload: TStoredMovs) => {
  setStorage(`usrStoredMovs_${id}`, payload);
}

export const getSecretStorage = (): TSecretStorageUser | null => {
  let data = localStorage.getItem("userSecretStorage");
  if (!data) return null;
  return JSON.parse(data);
}

export const destroyStorage = () => {
  let data = localStorage.getItem('userStorage');
  if (data) localStorage.removeItem('userStorage');
}