import { FC, useEffect, useState } from 'react'
import LayoutDefault from '../../../../adapters/layouts/default/index';

import ValidationSuccess from './components/ValidationSuccess';
import ValidateToken from './components/ValidateToken';
import { postValidateMobileNumberService } from '../../services/userAccount.service';
import useApi from '../../../../hooks/useApi.hook';

const ValidateMobileNumberContainer: FC = () => {
  const [verified, setVerified] = useState(false);
  const { data, isLoading, errorApi, handleApi } = useApi();

  const handlerSubmit = async (token: string) => {
    handleApi(token, postValidateMobileNumberService)
  }

  useEffect(() => {
    if (data) setVerified(true);
  }, [data])

  return (
    <LayoutDefault>
      {
        verified
          ? (<ValidationSuccess />)
          : (<ValidateToken handlerSubmit={handlerSubmit} isLoading={isLoading} errorApi={errorApi} />)
      }
    </LayoutDefault>

  );
}

export default ValidateMobileNumberContainer;