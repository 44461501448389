import moment from "moment";
import request from "../../../infra/request"
import { TStoredMov, getStorage, getStoredMovs, setStoredMovs } from "../../../storage";
import configEnv from '../../../utilities/config.utility';
import { FormatString } from "../../../utilities/validations.utility";

export const getCardsServices = async () => {
  try {
    const state = getStorage('userStorage');
    const headers = {
      authorization: `Bearer ${state?.token}`
    }

    const { data, statusText } = await request.get(configEnv.URL_getCards, headers);
    if (statusText) return { statusText };
    return data.oCards[0];

  } catch (e) {
    return { statusText: e }
  }
}

export const getCardInfo = async () => {
  try {
    const state = getStorage('userStorage');
    const headers = {
      authorization: `Bearer ${state?.token}`
    }

    const { data, statusText } = await request.get(FormatString(configEnv.URL_getInfo, [state!.id]), headers);
    if (statusText) return { statusText };
    return data;

  } catch (e) {
    return { statusText: e }
  }
}

export const getCardMovs = async () => {
  try {
    const state = getStorage('userStorage');
    const headers = {
      authorization: `Bearer ${state?.token}`
    }

    const { data, statusText } = await request.get(FormatString(configEnv.URL_getMovs, [state!.id]), headers);
    if (statusText) return { statusText };
    return data;

  } catch (e) {
    return { statusText: e }
  }
}


export const getStoredCardMovs = async () => {
  try {
    const state = getStorage('userStorage');

    let userStoredMovs = getStoredMovs(state!.id);
    if (!userStoredMovs) {
      userStoredMovs = {
        Movs: [],
        LastQueryDate: new Date(2023, 11, 20)
      };
    }

    let result: string | TStoredMov[] = [];
    let lastQueryDate: Date = moment(userStoredMovs.LastQueryDate).add(1, 'days').toDate();
    const today = moment().startOf('day').toDate();
    const yesterday = moment(today).subtract(1, 'day').toDate();
    if (!moment(lastQueryDate).subtract(1, 'day').isSame(yesterday)) {
      result = await fetchStoredCardMovs(lastQueryDate, yesterday);
      if (typeof result === "string") return { statusText: result };

      if (result.length < 25) {
        userStoredMovs.Movs.push(...result);
        userStoredMovs.LastQueryDate = yesterday;
        setStoredMovs(state!.id, userStoredMovs);
      }
      else {
        let nextDate = moment(lastQueryDate);
        do {
          result = await fetchStoredCardMovs(nextDate.toDate(), nextDate.toDate());
          if (typeof result === "string") return { statusText: result };
          userStoredMovs.Movs.push(...result);
          userStoredMovs.LastQueryDate = nextDate.toDate();
          setStoredMovs(state!.id, userStoredMovs);
          nextDate = moment(nextDate).add(1, 'days');
        } while (nextDate.isBefore(yesterday));
      }
    }

    result = await fetchStoredCardMovs(today, moment(today).add(1, 'days').toDate());
    if (typeof result === "string") return { statusText: result };
    const orderedRows = [...userStoredMovs.Movs, ...result].sort((x, y) => { return moment(x.Datetime).isAfter(moment(y.Datetime)) ? -1 : 1 });
    return { rows: orderedRows }

  } catch (e) {
    return { statusText: e }
  }
}

const fetchStoredCardMovs = async (fromDate: Date, toDate: Date) => {
  try {
    const state = getStorage('userStorage');
    const headers = {
      authorization: `Bearer ${state?.token}`
    }

    const { data, statusText } = await request.get(FormatString(configEnv.URL_getMovsPaged, [state!.id, moment(fromDate).format("YYYY-MM-DD"), moment(toDate).format("YYYY-MM-DD")]), headers);
    if (statusText) return statusText;
    return data;

  } catch (e) {
    return e
  }
}

export const setCardState = async (payload: any) => {
  try {
    const state = getStorage('userStorage');
    const headers = {
      authorization: `Bearer ${state?.token}`
    }

    const { data, statusText } = await request.put(FormatString(configEnv.URL_putState, [state!.id]), payload, headers);
    if (statusText) return { statusText };
    return data;

  } catch (e) {
    return { statusText: e }
  }
}