import { getStorage, setStorage } from "../../../storage";
import request from '../../../infra/request/index';
import configEnv from "../../../utilities/config.utility";

//////////////////////// EMAIL ///////////////////////////

export const postValidateEmailService = async (tokenUser: string) => {
  try {

    const state = getStorage('userStorage');
    const headers = {
      authorization: `Bearer ${state?.token}`
    }

    const dataPayload = {
      token: tokenUser,
      //email: state?.email
    }

    const { data, statusText } = await request.post(configEnv.URL_validateEmail, dataPayload, headers);
    if (statusText) return { statusText };
    return data;

  } catch (e) {
    return { statusText: e }
  }
}

export const postResendEmailValidationTokenService = async (payload: any) => {
  try {

    const state = getStorage('userStorage');
    const headers = {
      authorization: `Bearer ${state?.token}`
    }

    const { data, statusText } = await request.post(configEnv.URL_resendEmailValidationToken, payload, headers);
    if (statusText) return statusText;
    return data;

  } catch (e) {
    return { statusText: e }
  }
}
//////////////////////// EMAIL ///////////////////////////

//////////////////////// MOBILE NUMBER ///////////////////////////
export const postRegisterMobileNumberService = async (mobileNumber: string) => {
  try {

    const state = getStorage('userStorage');
    const headers = {
      authorization: `Bearer ${state?.token}`
    }

    const dataPayload = {
      mobileNumber
    }

    const { data, statusText } = await request.post(configEnv.URL_registerMobileNumber, dataPayload, headers)
    if (statusText) return { statusText };

    if (data) {
      if (data.mobileNumber)
        setStorage('userStorage', { ...state, mobileNumber: data.mobileNumber });
      //else
      //setStorage_mobileNumber('userStorage', data.mobileNumber);

      return data;
    }
    else
      return { statusText: "Respuesta inválida del seridor..." };

  } catch (e) {
    return { statusText: e }
  }
}

export const postValidateMobileNumberService = async (token: string) => {
  try {

    const state = getStorage('userStorage');
    const headers = {
      authorization: `Bearer ${state?.token}`
    }

    const payload = {
      //mobileNumber: state?.mobileNumber,
      token
    }

    const { data, statusText } = await request.post(configEnv.URL_validateMobileNumber, payload, headers)
    if (statusText) return { statusText };
    return data;

  } catch (e) {
    return { statusText: e }
  }
}

export const postResendMobileNumberValidationTokenService = async () => {
  try {

    const state = getStorage('userStorage');
    const headers = {
      authorization: `Bearer ${state?.token}`
    }

    const payload = {
      //mobileNumber: state?.mobileNumber,
    }

    const { data, statusText } = await request.post(configEnv.URL_resendMobileNumberValidationToken, payload, headers)
    if (statusText) return { statusText };
    return data;

  } catch (e) {
    return { statusText: e }
  }
}
//////////////////////// MOBILE NUMBER ///////////////////////////

//////////////////////// ADDRESS ///////////////////////////

export const registerAddressService = async (payload: any) => {
  try {

    const state = getStorage('userStorage');
    const headers = {
      authorization: `Bearer ${state?.token}`
    }

    const { data, statusText } = await request.post(configEnv.URL_registerAddress, payload, headers);
    if (statusText) return { statusText };
    return data;

  } catch (e) {
    return { statusText: e }
  }
}

//////////////////////// ADDRESS ///////////////////////////