import { FC } from 'react'
import BaseButton from '../../../../../baseComponents/BaseButton';
import BaseIcon from '../../../../../baseComponents/BaseIcons';
import LayoutDefault from '../../../../../adapters/layouts/default/index';

const AddressSuccess: FC = () => {

  // TODO: cambiamos la ruta from /kyc to /
  return (
    <LayoutDefault>
      <div className='justify-center mb-4'>
        <BaseIcon icon='icon-success' width={50} height={50} />
      </div>
      <div className='title mb-1'>Hemos Registrado tu Dirección</div>
      <div className='subtitle mb-4'>ahora, el último paso!!!!</div>

      <BaseButton title='Iniciar' toLink='/kyc' />
    </LayoutDefault>
  );
}

export default AddressSuccess;