import { FC, useEffect, useState } from 'react'
import LayoutDefault from '../../adapters/layouts/default/index';
import BaseButton from '../../baseComponents/BaseButton';
import BaseInput from '../../baseComponents/BaseInput';

import styles from './styles/register.module.scss';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { validateEmail, validatePassword } from '../../utilities/validations.utility';
import { postRegisterUserService } from '../../services/account.service';
import useApi from '../../hooks/useApi.hook';

const Register: FC = () => {
  const navigate = useNavigate();

  const search = useLocation().search;
  const uuid = new URLSearchParams(search).get('uuid');

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPass, setShowPass] = useState(false);
  const [errorEmail, setErrorEmail] = useState('');
  const [errorPass, setErrorPass] = useState('');
  
  const { data, errorApi, isLoading, handleApi } = useApi();

  useEffect(() => {
    if(email && password) {
      validateEmail(email, setErrorEmail);
      validatePassword(password, setErrorPass);
    }
  }, [email, password]);

  const handlerSubmit = async () => {
    const bEmail = validateEmail(email, setErrorEmail);
    const bPass = validatePassword(password, setErrorPass);
    if (bEmail && bPass && uuid) 
      handleApi({ email, password, uuid }, postRegisterUserService);
  }

  useEffect(() => {
    if (data) navigate('/validate-email');
  }, [data, navigate])

  return (
    <LayoutDefault>
      <div className='title mb-4'> {uuid ? "Registra tu cuenta" : "Escanea tu código QR"}<br /> para comenzar</div>

      {uuid && (
        <>
          <div className={styles.input}>
            <BaseInput placeholder='Ingresa tu correo' error={errorEmail} icon='icon-email' onChange={(e) => setEmail(e)} />
          </div>

          <div className={styles.input}>
            <BaseInput
              error={errorPass}
              type={`${showPass ? 'text' : 'password'}`}
              onChange={(e) => setPassword(e)}
              placeholder='Contraseña' icon={`${showPass ? 'icon-hide-password' : 'icon-show-password'}`}
              iconEvent={() => setShowPass(!showPass)} />
          </div>

          <div className='error-message m-2'>{errorApi}</div>

          <BaseButton title='Continuar' onClick={handlerSubmit} isLoading={isLoading} />
        </>
      )}

      <div className='extra-info'>Si ya tienes cuenta, <Link to={'/login'} className='underline'> haz click aqui </Link></div>
    </LayoutDefault >
  )
}

export default Register;