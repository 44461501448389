import { FC, useEffect, useState } from 'react'
import LayoutDefault from '../../../../adapters/layouts/default/index';

import ValidationSuccess from './components/ValidationSuccess';
import ValidateToken from './components/ValidateToken';
import { postValidateEmailService } from '../../services/userAccount.service';
import useApi from '../../../../hooks/useApi.hook';

const ValidateEmailContainer: FC = () => {
  const { data, isLoading, errorApi, handleApi } = useApi();
  const [verified, setVerified] = useState(false)

  const handlerSubmit = async (tokenUser: string) => {
    handleApi(tokenUser, postValidateEmailService);
  }

  useEffect(() => {
    if (data) setVerified(true);
  }, [data])

  return (
    <LayoutDefault>
      {
        verified
          ? (<ValidationSuccess />)
          : (<ValidateToken handlerSubmit={handlerSubmit} isLoading={isLoading} errorApi={errorApi} />)
      }
    </LayoutDefault>

  );
}

export default ValidateEmailContainer;