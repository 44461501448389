/**
 * 
 * @param email 
 * @param cb callback (prefered useState)
 * @returns 
 */
export const validateEmail = (email: string, cb: (k: string) => void) => {
  const emailValidator = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const bVALID = email.match(emailValidator);
  cb(bVALID ? "" : "Por favor, verifica la direccion de correo");
  return bVALID;
}

export const validatePassword = (value: string, cb: (k: string) => void) => {
  const bVALID = value.length >= 8;
  cb(bVALID ? "" : "Al menos 8 caracteres!");
  return bVALID;
};

export const validateInput = (value: string, len: number | 8, blankMessage: string | null, invalidMessage: string | null, cb: (k: string) => void) => {
  const bVALID = value.length >= len;
  cb(bVALID ? blankMessage || "" : invalidMessage || `Al menos ${len} caracteres!`);
  return bVALID;
};

export const validateInputExactLen = (value: string, len: number | 8, blankMessage: string | null, invalidMessage: string | null, cb: (k: string) => void) => {
  const bVALID = value.length === len;
  cb(bVALID ? blankMessage || "" : invalidMessage || `It should be ${len} numbers!`);
  return bVALID;
};

export function FormatString(value: string, params: string[]) {

  let formatted = value;
  for (let i = 0; i < params.length; i++) {
    let regexp = new RegExp('\\{' + i + '\\}', 'gi');
    formatted = formatted.replace(regexp, params[i]);
  }
  return formatted;
};