import { FC } from 'react'
import BaseButton from '../../../../../baseComponents/BaseButton';
import BaseIcon from '../../../../../baseComponents/BaseIcons';


const ValidationSuccess: FC = () => {

  return (
    <div>
      <div className='justify-center mb-4'>
        <BaseIcon icon='icon-success' width={50} height={50} />
      </div>
      <div className='title mb-1'>Email verificado!</div>
      <div className='subtitle mb-4'>Tu email ha sido verificado correctamente</div>

      <BaseButton title='Continuar' toLink='/register-mobileNumber' />
    </div>
  );
}

export default ValidationSuccess;