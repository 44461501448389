import { FC, useEffect, useState } from 'react'

import 'react-phone-input-2/lib/style.css'
import PhoneInput from "react-phone-input-2";
import LayoutDefault from '../../../../adapters/layouts/default/index';
import { postRegisterMobileNumberService } from '../../services/userAccount.service';
import { useNavigate } from 'react-router-dom';
import BaseButton from '../../../../baseComponents/BaseButton';
import useApi from '../../../../hooks/useApi.hook';

import styles from './styles/RegisterMobileNumber.module.scss';
import { validateInput } from '../../../../utilities/validations.utility';

const RegisterMobileNumber: FC = () => {
  const [mobileNumberError, setMobileNumberError] = useState('');
  const [mobileNumber, setMobileNumber] = useState('');
  const navigate = useNavigate();
  const { data, isLoading, errorApi, handleApi } = useApi();

  const validateSubmit = async (mobileNumber: string) => {
    const bTokenLen = validateInput(mobileNumber, 6, null, "Ingresa un número de teléfono válido...", setMobileNumberError);
    if (bTokenLen) handleApi(`+${mobileNumber}`, postRegisterMobileNumberService);
  }

  useEffect(() => {
    if (data) navigate('/validate-mobileNumber');
  }, [data, navigate])

  return (
    <LayoutDefault>

      <div className='title mb-4'>Registra tu número <br /> de teléfono</div>
      <div className={`w-100 mb-4 ${styles.control__input}`}>
        <PhoneInput
          inputProps={{
            name: 'mobileNumber'
          }}
          onChange={(e) => setMobileNumber(e)}
          country={'co'}
          placeholder={'Indica tu número de teléfono'}
        />
      </div>

      <div className='error-message mb-4'>{errorApi || mobileNumberError}</div>

      <BaseButton title='Continuar' onClick={() => validateSubmit(mobileNumber)} isLoading={isLoading} />

    </LayoutDefault >

  );
}

export default RegisterMobileNumber;