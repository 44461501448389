import { FC, ReactNode } from "react"
import Header from "./components/Header";

import styles from './styles/layout.module.scss';
import { useNavigate } from 'react-router-dom';
import { destroyStorage, getStorage } from "../../../storage";
import BaseIcon from "../../../baseComponents/BaseIcons";

const LayoutDefault: FC<{ children: ReactNode }> = ({ children }) => {
  const navigate = useNavigate();
  const stateStorage = getStorage('userStorage');

  const exit = () => {
    navigate('/login');
    destroyStorage();
  }
  return (
    <div className={`${styles.container}`}>
      <Header />
      {stateStorage && (<div aria-hidden className={`${styles.logout} m-4`} onClick={() => exit()}>
        <BaseIcon icon="icon-logout" height={20} />
        EXIT
      </div>)}
      <div className={styles.box}>
        {children}
      </div>
    </div>
  )
}


export default LayoutDefault;
