import { FC, useEffect, useState } from 'react'
import LayoutDefault from '../../../../adapters/layouts/default';
import BaseButton from '../../../../baseComponents/BaseButton';
import useScriptKYC from '../../../../hooks/useScriptKYC';
import { getStorage } from '../../../../storage';
import { destroyStorage } from '../../../../storage/index';
//@ts-nocheck

interface MetamapButton {
  id: string,
  clientid: string,
  flowId: string,
  metadata?: any
}

type MetamapButtonMix = React.HTMLAttributes<HTMLElement>
type Mezcla = MetamapButton | MetamapButtonMix


declare global {
  namespace JSX {
    interface IntrinsicElements {
      'metamap-button': React.DetailedHTMLProps<Mezcla, HTMLElement>;
    }
  }
}

const Kyc: FC = () => {

  const redirectToLogin = () => {
    destroyStorage();
    window.location.href = '/login';
  }

  const user = getStorage('userStorage');
  useEffect(() => {
    if (!user?.token) redirectToLogin();
    setMetadata(JSON.stringify({ kyc_id: user?.id, JWT: user?.token }))
  }, [user]);

  const [title, setTitle] = useState('Ultimo paso!');
  const [subTitle1, setSubTitle1] = useState('Necesitamos validar tu identidad.');
  const [subTitle2, setSubTitle2] = useState('');
  const [metadata, setMetadata] = useState('');
  const [finishedStatus, setFinishedStatus] = useState(0);
  const [endButtonTitle, setEndButtonTitle] = useState('Redirigiendo');
  const [endButtonType, setEndButtonType] = useState('primary');

  const setFinished = (iShowEndButton: number, sEndButtonTitle: string, sEndButtonType: string, next: NodeJS.Timeout | null) => {
    setFinishedStatus(iShowEndButton);
    setEndButtonTitle(sEndButtonTitle);
    setEndButtonType(sEndButtonType);
    if (next) return next;
  }

  const kycCallback = (statusCode: string, iResult: number) => {
    {
      console.log('StatusCode', statusCode);
      switch (statusCode) {
        case 'missingPhotos':
          setTitle('Ultimo paso!');
          setSubTitle1('Necesitamos validar tu identidad.');
          setFinished(0, "", "", null);
          break;
        case 'processing':
          setTitle('Ultimo paso!');
          setSubTitle1('Procesando la verificación de tu identidad...');
          setFinished(2, "", "", null);
          break;
        case 'manual':
          setTitle('Verificación Manual!');
          setSubTitle1('Estamos verificandto de tu identidad manualmente...');
          setSubTitle2('Esto no debe tomarnos mas de unas horas.');
          setFinished(1, "Procesando...", "primary", null);
          break;
        case 'profileData':
          setTitle('Paciencia!');
          setSubTitle1('Estamos verificandto de tu información manualmente...');
          setSubTitle2('Esto no debe tomarnos mas de unas horas.');
          setFinished(1, "Procesando...", "primary", null);
          break;
        case 'setingUp':
          setTitle('Ya casi terminamos!');
          setSubTitle1('Estamos configurando tu tarjeta.');
          setSubTitle2('Esto no debe tomar mas de unos minutos...');
          setFinished(1, "Procesando...", "primary", null);
          break;
        case 'blocked':
          setTitle('Mmmmmm!');
          setSubTitle1('No podes ingresar.');
          setFinished(1, "Salir", "primary", setTimeout(() => { window.location.href = '/login' }, 10000));
          break;
        case 'done':
          setTitle('LISTO!!!');
          setSubTitle1('EMPECEMOS');
          setFinished(1, "Ingresar", "primary", setTimeout(() => { window.location.href = '/my-card' }, 2000));
          break;
        case 'abandoned':
          setTitle('Mmmmmm...');
          setSubTitle1('Cancelaste el proceso.');
          setSubTitle2('Vuelve a hacer loguin para reintentarlo...');
          setFinished(1, "Login", "primary", setTimeout(() => { window.location.href = '/login' }, 20000));
          break;
      }
    }
    window.location.href = '/login';
  }

  const { loadScript, removeScript, subscribeEvents } = useScriptKYC(kycCallback);

  useEffect(() => {
    loadScript('https://web-button.mati.io/button.js');
    return () => removeScript();
    //<metamap-button id="btnMati" clientid="609178bec67f4a001be67a5a" flowId="630404d8946728001d6fb2cf" metadata={metadata} />
  }, [loadScript, removeScript]);

  const handleButton = () => {
    subscribeEvents();
  }

  return (
    <LayoutDefault>
      <>
        <div className='title mb-1'>{title}</div>
        <div className='subtitle mt-4'>{subTitle1}</div>
        <div className='subtitle mb-4'>{subTitle2}</div>
        {(finishedStatus >= 1)
          ? ((finishedStatus == 1) ?
            (<BaseButton title={endButtonTitle} type={endButtonType} onClick={() => { }} />)
            :
            (<></>))
          : (<BaseButton title='Iniciar' onClick={() => handleButton()} />)
        }
        <div id="divMatiButton">

        </div>


      </>
    </LayoutDefault>
  );
}

export default Kyc;