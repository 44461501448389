import { FC, useState } from 'react'
import moment from 'moment'

import styles from './movs.module.scss';
import BaseIcon from '../../../../../baseComponents/BaseIcons';

interface IMovs {
  date?: string,
  amount?: number,
  destAmount?: number,
  destCurrency?: string,
  type?: string,
  status?: string,
  description?: string
}
const Movs: FC<IMovs> = ({
  date = '-',
  amount = 0,
  destAmount = 0,
  destCurrency = 'ARS',
  type = '-',
  status = '-',
  description = '-'
}) => {

  let sIcon = "";
  let sBadge = "Other";

  if (type === "Carga" && description === "") {
    type = "Load";
    description = "Enjoy your Pesos!";
    sBadge = "Carga";
  }

  if (type === "Ajuste" && description === "Descarga tarjeta prepago") {
    type = "Unload";
    description = "";
    sBadge = "Descarga";
  }

  if (type === "Ajuste" && description !== "Descarga tarjeta prepago") {
    type = "Other";
    sBadge = "Other";
  }

  if (type === "Adelanto") {
    type = "Cash Withdraw";
    sIcon = 'mov-cash';
    amount = destAmount;
  }

  if (type === "Consumo") {
    type = status === "APROBADA" ? "Expense" : "Refund";
    sBadge = status === "APROBADA" ? "Consumo" : "ConsumoFail";
    sIcon = `mov-${status === "APROBADA" ? "expense" : "status-fail"}`;
  }

  return (
    <div className={styles.movRow}>
      <div className={styles.movRowLine}>
        <div className={styles.movRow__cardMovs_A}>
          {moment(date.split('T')[0]).format('MMM DD')}<br /><span className={styles.movHour}>{moment('2020-01-01 ' + date.split('T')[1]).format('LT')}</span>
        </div>
        <div className={styles.movRow__cardMovs_B}>
          <small>ARS </small><b>{amount.toFixed(2)}</b>
          {(destCurrency === "ARS") ? "" :
            (
              <>
                <br /> <small>{destCurrency} {destAmount.toFixed(2)} </small>
              </>
            )
          }
        </div>
        <div className={styles.movRow__cardMovs_C}>
          <div className={`${styles.badge} ${styles[`badge${sBadge}`]}`}>{type} {sIcon && <BaseIcon icon={sIcon} width={13} height={13} />}</div>
        </div>
        <div className={styles.movRow__cardMovs_D}>
          {description}
        </div>
      </div>
      <div className={styles.movRow__cardMovs_DXS}>
        <b>Desc: </b>{description}
      </div>
    </div>
  );
}

export default Movs;