import { Link } from "react-router-dom";
import BaseIcon from "../BaseIcons";
import BaseLoading from "../BaseLoading";

import styles from "./baseButton.module.scss";

export type TIndexString = {
  [key: string]: string
}

type TButton = "button" | "submit" | "reset" | undefined;

type TBaseButton = {
  title?: string
  type?: string
  margin?: number
  zIndex?: number
  cssClass?: string
  size?: string
  onClick?: (e: any) => void,
  isLoading?: boolean
  disabled?: boolean
  icon?: string
  toLink?: string
  typeButton?: TButton
}

const BaseButton: React.FC<TBaseButton> = ({
  title = "",
  typeButton = "button",
  cssClass = null,
  margin = 2,
  zIndex = null,
  type = "primary",
  size = "full",
  isLoading = false,
  onClick = (e) => e,
  disabled = false,
  icon = null,
  toLink = null,
}) => {
  const getTypeButton = (typex: string) => {
    const types: TIndexString = {
      primary: "btn-primary",
      secondary: "btn-secondary",
      success: "btn-success",
      warning: "btn-warning",
      danger: "btn-danger",
      info: "btn-info",
      link: "btn-link",
      light: "btn-light",
    };
    return types[typex];
  };

  const getSize = (sizex = "full") => {
    const types: TIndexString = {
      xs: "w-25",
      medium: "w-50",
      large: "w-75",
      full: "w-100",
    };
    return types[sizex];
  };

  const getZindex = (sizex: number) => {
    const types: TIndexString = {
      "0": "zIndex0",
      "1": "zIndex1",
      "2": "zIndex2",
      "-1": "zIndex-1",
    };
    return types[sizex];
  };

  return (
    <div
      aria-hidden
      className={`${styles.container} ${zIndex != null ? getZindex(zIndex) : ""} ${getSize(size?.toString())} m-${margin}`}
      onClick={disabled || isLoading ? undefined : onClick}
    >
      {icon && (
        <div className={styles.icon}>
          <BaseIcon icon={icon} />
        </div>
      )}

      {
        toLink ? (
          <Link
            to={disabled ? "#" : toLink}
            onClick={onClick}
            title={title}
            className={`btn ${getSize(size)} ${cssClass ? cssClass : getTypeButton(type)} ${styles.button} ${disabled && styles.disabled}`}
          >
            {title}
          </Link>
        )
          : (
            <button
              type={typeButton as TButton}
              className={`btn ${getSize(size)} ${cssClass ? cssClass : getTypeButton(type)} ${styles.button} ${(disabled || isLoading) && styles.disabled}`}
              disabled={disabled || isLoading}
              title={title}
            >
              {isLoading ? <BaseLoading isLoading width={30} /> : !icon && title}
            </button>
          )
      }
    </div >
  );
};

export default BaseButton;
