import { FC } from 'react'
import BaseButton from '../../../../../baseComponents/BaseButton';
import BaseIcon from '../../../../../baseComponents/BaseIcons';


const RecoverPasswordSuccess: FC = () => {

  return (
    <div>
      <div className='justify-center mb-4'>
        <BaseIcon icon='icon-success' width={50} height={50} />
      </div>
      <div className='title mb-1'>Contraseña reestablecida!</div>
      <div className='subtitle mb-4'>Ingresa a tu cuenta para utilizar tu tarjeta Kill B</div>

      <BaseButton title='Iniciar sesión' toLink='/login' />
    </div>
  );
}

export default RecoverPasswordSuccess;