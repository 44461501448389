import { FC, useEffect, useState } from 'react'
import LayoutDefault from '../../../../adapters/layouts/default';
import BaseButton from '../../../../baseComponents/BaseButton';
import BaseImage from '../../../../baseComponents/BaseImage';

import styles from './styles/card.module.scss';

import { getCardInfo, getCardMovs, getStoredCardMovs, setCardState } from '../../services/card.service';
import { getExchange } from '../../services/crypto.service';
import BaseLoading from '../../../../baseComponents/BaseLoading';

import { useMutation, useQuery } from 'react-query';
import { getSecretStorage } from '../../../../storage';
import Movs from './Components/Movs';
import ModalInstructions from './ModalInstructions';
import BaseFlip from '../../../../baseComponents/BaseFlip';
import ToggleSwitch from '../../../../baseComponents/BaseToggle';
import ModalFeedback from './ModalFeedback';

const oCardInfoData = {
  Id: "",
  Balance: "",
  Pin: "",
  ExpiryDate: "",
}
const MyCard: FC = () => {
  const secretState = getSecretStorage();
  const [exchangeRate, setExchangeRate] = useState(0);
  const [checked, setChecked] = useState(true);
  const [disabled, setDisabled] = useState(true);
  const [errorMovs, setErrorMovs] = useState('');
  const { data, isLoading, isError, isFetched } = useQuery(`${secretState?.secret!}Info`, getCardInfo);
  const { data: dataMovs, isLoading: isLoadingMovs, isError: isErrorMovs } = useQuery(`${secretState?.secret!}Movs`, getStoredCardMovs);

  const getExchangeRate = useMutation("getExchange", getExchange, {
    onSuccess: (value: any) => {
      setExchangeRate(value);
    }
  });

  const setStatus = useMutation("setCardStatus", setCardState, {
    onSuccess: (value: any) => {
      setChecked((value.State === "Enabled"));
      setDisabled(false);
    },
    onError: (error, variables, context) => {
      setDisabled(false);
    }
  });

  const changeCardState = (value: boolean) => {
    setDisabled(true);
    setStatus.mutate({ state: value ? "enabled" : "disabled" });
  };

  useEffect(() => {
    if (isFetched) {
      setChecked(data.State === "Enabled");
      setDisabled(false);
      if (data?.balance) {
        getExchangeRate.mutate(data.balance);
      }
    }
    if (!data?.balance) {
      setExchangeRate(0);
    }
  }, [data]);

  const [showModalInstructions, setShowModalInstructions] = useState(false);
  const loadCardInstructions = () => {
    setShowModalInstructions(!showModalInstructions)
  }

  const [showModalFeedback, setShowModalFeedback] = useState(false);
  const loadFeedback = () => {
    setShowModalFeedback(!showModalFeedback)
  }

  useEffect(() => {
    if (isErrorMovs) {
      setErrorMovs('Error getting movements');
    }
    if (isError) {
      setErrorMovs('Error getting balance');
    }
  }, [isErrorMovs, isError]);

  return (
    <LayoutDefault>
      {isLoading
        ? (<BaseLoading isLoading={isLoading} />)
        : (<>
          <div className={styles.cardBox}>
            <div className={styles.cardTitle}>
              <div className={styles.cardTitle_HI}>
                <div className='mb-1 font-extra-big fw-bold'>Hi Cardholder!!!</div>
                <div className='mb-4'>This is your <b>Anclap Card ID</b> {data?.Id}...</div>
              </div>
              <div className={styles.cardBalanceLG}>
                <div className='mb-0 mt-5 font-medium fw-500 text-dark-secondary'>BALANCE</div>
                <div className='row mb-2 mt-1 d-inline-flex'>
                  <BaseImage image='icon-ars' width={'30'} height={'30'} />
                  <div className='d-inline-flex'>
                    <div className={`${styles.Asset} font-regular`}>ARS</div>${data?.balance}
                    <div className={`${styles.Asset} font-regular`}> ~ </div><BaseImage image='icon-usdc' width={'18'} height={'18'} style={'mt-2'} />
                    <div className={`${styles.Asset} font-regular`}>USDC</div>${exchangeRate}
                  </div>
                </div>
                <div className='d-inline-block mt-0'>
                  <div className='mb-2 mt-3 font-medium fw-500 text-dark-secondary'>STATUS<span></span></div>
                  <ToggleSwitch id="stateLG" small={false} checked={checked} disabled={disabled} onChange={changeCardState} optionLabels={["Active", "Inactive"]} />
                </div>
              </div>
            </div>

            <div className={styles.cardImage}>
              <BaseFlip data={data} secret={secretState?.secret}></BaseFlip>
              <div className={styles.cardBalance}>
                <div className='mb-1 font-medium fw-500 text-dark-secondary'>BALANCE</div>
                <div className='row mb-2 mt-2 d-inline-flex font-big'>
                  <BaseImage image='icon-ars' width={'30'} height={'30'} />
                  <div className='d-inline-flex'>
                    <div className={`${styles.Asset} font-regular d-inline-flex`}>ARS</div>${data?.balance}
                    <div className={`${styles.Asset} font-regular`}> ~ </div><BaseImage image='icon-usdc' width={'20'} height={'20'} style={'mt-2'} />
                    <div className={`${styles.Asset} font-regular`}>USDC</div>${exchangeRate}
                  </div>
                </div>
                <div className='d-inline-block mt-0'>
                  <div className='mb-2 mt-3 font-medium fw-500 text-dark-secondary'>STATUS<span></span></div>
                  <ToggleSwitch id="state" small={false} checked={checked} disabled={disabled} onChange={changeCardState} optionLabels={["Active", "Inactive"]} />

                </div>
              </div>
              <BaseButton title={"Load Balance"} cssClass={styles.cardLoadBtn} onClick={loadCardInstructions} margin={0} zIndex={-1} />

            </div>
          </div>

          <div className={styles.cardMovs}>
            <BaseButton title={"GIVE US FEEDBACK !!!"} cssClass={styles.cardFeedbackBtn} onClick={loadFeedback} margin={0} type='primary'></BaseButton>
            <div className='mb-1 font-medium fw-500 text-dark-secondary'>MOVEMENTS</div>
            {!dataMovs ? (<BaseLoading isLoading={isLoadingMovs} style={{ paddingTop: '100px' }} />)
              : (
                <div className={styles.movRowH}>
                  <div className={styles.movRowH__cardMovs_A}>Date</div>
                  <div className={styles.movRowH__cardMovs_B}>Amount</div>
                  <div className={styles.movRowH__cardMovs_C}>Type</div>
                  <div className={styles.movRowH__cardMovs_D}>Description</div>
                </div>
              )}

            {dataMovs && dataMovs.rows?.map((mov: any, key: any) => (
              <Movs key={key} date={mov.Datetime} amount={mov.AmountInCardCurrency} destAmount={mov.AmountInDestionationCurrency} destCurrency={mov.DestionationCurrency} status={mov.State} description={mov.Description} type={mov.Type} />
            ))}

            {dataMovs && (dataMovs.rows?.length === 0) && (
              <div className={styles.movNoMov}>- There is no movements info -</div>
            )}
          </div>

          {errorMovs !== '' && (
            <div className={styles.error}> {errorMovs}</div>
          )}
          <ModalInstructions showModal={() => setShowModalInstructions(!showModalInstructions)} isVisible={showModalInstructions} data={data?.Id} />
          <ModalFeedback showModal={() => setShowModalFeedback(!showModalFeedback)} isVisible={showModalFeedback} data={data?.Id} />
        </>)
      }
    </LayoutDefault >
  );
}

export default MyCard;