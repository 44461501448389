import { IConfig } from "./interfaces/config.interface";

const apiKey = {
  MATI_CLIENT_ID: process.env.REACT_APP_MATI_CLIENT_ID,
  MATI_FLOW_ID: process.env.REACT_APP_MATI_FLOW_ID
}

const authApi = {
  URL_registerUser: `${process.env.REACT_APP_API_URL}/api/users/registerUser`,
  URL_validateEmail: `${process.env.REACT_APP_API_URL}/api/users/validateEmail`,
  URL_resendEmailValidationToken: `${process.env.REACT_APP_API_URL}/api/users/resendEmailValidationToken`,
  URL_registerMobileNumber: `${process.env.REACT_APP_API_URL}/api/users/registerMobileNumber`,
  URL_validateMobileNumber: `${process.env.REACT_APP_API_URL}/api/users/validateMobileNumber`,
  URL_resendMobileNumberValidationToken: `${process.env.REACT_APP_API_URL}/api/users/resendMobileNumberValidationToken`,
  URL_registerAddress: `${process.env.REACT_APP_API_URL}/api/users/registerAddress`,
}

const cryptoApi = {
  URL_getCryptoAddresses: `${process.env.REACT_APP_API_URL}/api/cripto/getAddresses`,
}

const cardApi = {
  URL_getCards: `${process.env.REACT_APP_API_URL}/api/card/byuser`,
  URL_getInfo: `${process.env.REACT_APP_API_URL}/cards/{0}`,
  URL_getMovs: `${process.env.REACT_APP_API_URL}/cards/{0}/transactions`,
  URL_getMovsPaged: `${process.env.REACT_APP_API_URL}/cards/{0}/transactions/{1}/{2}`,
  URL_putState: `${process.env.REACT_APP_API_URL}/cards/{0}`,
}

const configEnv: IConfig = {
  enviroment: process.env.REACT_APP_ENV || 'development',
  URL_loginUser: `${process.env.REACT_APP_API_URL}/cards/{0}/login`,
  ...authApi,
  ...cardApi,
  ...cryptoApi,
  ...apiKey,
}

export default configEnv;