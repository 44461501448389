
import {
  Routes,
  Route,
} from "react-router-dom";
import '../../styles/globals.scss';

import Auth from "./guards/AuthGuard";

import Register from "../../pages/Register";
import Login from "../../pages/Login";
import RecoverPassword from '../../pages/Auth/Pages/RecoverPassword/index';
import ValidateEmailContainer from "../../pages/Auth/Pages/ValidateEmail";
import RegisterMobileNumber from "../../pages/Auth/Pages/RegisterMobileNumber";
import ValidateMobileNumberContainer from "../../pages/Auth/Pages/ValidateMobileNumber";
import RegisterAddress from "../../pages/Auth/Pages/RegisterAddress";
import Kyc from "../../pages/Auth/Pages/Kyc";
import MyCard from "../../pages/Auth/Pages/MyCard";
import HomePreLaunch from '../../pages/Login/home';
import { QueryClient, QueryClientProvider } from "react-query";

const queryClient = new QueryClient()
const App = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/login" element={<Login />} />
        <Route path="/recover-password" element={<RecoverPassword />} />

        <Route path="/home" element={<HomePreLaunch />} />
        <Route path="/register" element={<HomePreLaunch />} />
        <Route path="/register2" element={<Register />} />
        <Route path="/validate-email" element={<ValidateEmailContainer />} />
        <Route path="/register-mobileNumber" element={<RegisterMobileNumber />} />
        <Route path="/validate-mobileNumber" element={<ValidateMobileNumberContainer />} />
        <Route path="/register-address" element={<RegisterAddress />} />

        <Route path="/kyc" element={<Kyc />} />
        <Route element={<Auth />}>
          <Route path="/my-card" element={<MyCard />} />
        </Route>

      </Routes>
    </QueryClientProvider>
  );
};

export default App;
