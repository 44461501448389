import React from "react";
import ReactDOM from "react-dom";
import { Range, getTrackBackground } from "react-range";

const STEP = 0.1;
const MIN = 0;
const MAX = 100;

type TBaseRange = {
  min?: number;
  max?: number;
  step?: number;
  stateValues: any,
  onChange?: (values: any) => void;
  onFinalChange: (values: any) => void;
}

const BaseRange = ({
  min = MIN,
  max = MAX,
  step = STEP,
  stateValues,
  onChange = (values: any) => { console.log(values) },
  onFinalChange = (values: any) => { console.log(values) },
}: TBaseRange) => {

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        flexWrap: "wrap",
        marginTop: "3em",
        marginLeft: "3em",
        marginRight: "3em",
        marginBottom: "1.2em"
      }}
    >
      <Range
        values={stateValues}
        step={step}
        min={min}
        max={max}
        onFinalChange={(values: number[]) => onFinalChange(values)}
        onChange={(values) => onChange(values)}
        disabled={false}
        renderTrack={({ props, children }) => (
          <div
            onMouseDown={props.onMouseDown}
            onTouchStart={props.onTouchStart}
            style={{
              ...props.style,
              height: "30px",
              display: "flex",
              width: "100%"
            }}
          >
            <div
              ref={props.ref}
              style={{
                height: "5px",
                width: "100%",
                borderRadius: "4px",
                background: getTrackBackground({
                  values: stateValues,
                  colors: ["#548BF4", "#ccc"],
                  min: min,
                  max: max
                }),
                alignSelf: "center"
              }}
            >
              {children}
            </div>
          </div>
        )}
        renderThumb={({ props, isDragged }) => (
          <div
            {...props}
            style={{
              ...props.style,
              height: "25px",
              width: "25px",
              borderRadius: "20px",
              backgroundColor: "#FFF",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              boxShadow: "0px 2px 6px #AAA"
            }}
          >
            <div
              style={{
                position: 'absolute',
                top: '-32px',
                color: '#fff',
                fontWeight: 'bold',
                fontSize: '14px',
                display: 'block',
                minWidth: '90PX',
                padding: '4px',
                borderRadius: '4px',
                backgroundColor: '#548BF4'
              }}
            >
              {stateValues[0].toFixed(1)} USDC
            </div>
            <div
              style={{
                height: "10px",
                width: "4px",
                backgroundColor: isDragged ? "#548BF4" : "#CCC"
              }}
            />
          </div>
        )}
      />
    </div>
  );
}

export default BaseRange;