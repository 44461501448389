import { FC, useState, useEffect } from 'react'
import LayoutLogin from '../../adapters/layouts/login/index';
import BaseButton from '../../baseComponents/BaseButton';
import BaseInput from '../../baseComponents/BaseInput';

import styles from './styles/login.module.scss';
import { Link, useNavigate } from 'react-router-dom';
import { validateInputExactLen } from '../../utilities/validations.utility';
import { postLoginUserService } from '../../services/account.service';
import useApi from '../../hooks/useApi.hook';
import { mapRoutes } from '../Auth/adapters';
import { privateRoutes } from '../../infra/routes/routes.path';
import { destroyStorage, getSecretStorage, getStorage } from '../../storage';
import Guard from '../../infra/routes/guards/AuthGuard';
import jwt_decode from "jwt-decode";

const Login: FC = () => {
  const navigate = useNavigate();
  const user = getStorage("userStorage");

  const [id, setID] = useState('');
  const [secret, setSecret] = useState('');
  const [showPass, setShowPass] = useState(false);
  const [errorEmail, setErrorEmail] = useState('');
  const [errorPass, setErrorPass] = useState('');

  const { data, errorApi, isLoading, handleApi } = useApi();

  const validateAuth = (user: any) => {
    if (!user?.token) {
      return false;
    }
    else {
      const decoded: any = jwt_decode(user.token);
      if (Date.now() >= decoded.exp * 1000) {
        return false;
      }
    }
    return true;
  };

  useEffect(() => {
    if (validateAuth(user)) {
      navigate(privateRoutes.MYCARD);
    }
  }, [user]);

  useEffect(() => {
    if (id && secret) {
      validateInputExactLen(id, 6, null, null, setErrorEmail);
      validateInputExactLen(secret, 8, null, null, setErrorPass);
    }
  }, [id, secret]);

  useEffect(() => {
    if (data)
      navigate(mapRoutes(data.idOperationalStatus));
  }, [data, navigate]);

  const handleLogin = async () => {
    destroyStorage();
    const bEmail = validateInputExactLen(id, 6, null, null, setErrorEmail);
    const bPass = validateInputExactLen(secret, 8, null, null, setErrorPass);
    if (bEmail && bPass)
      handleApi({ id, secret }, postLoginUserService);
  }

  return (
    <LayoutLogin>

      <div className='title mb-4'>ANCLAP CARDS</div>

      <div className={styles.input}>
        <BaseInput placeholder='Card ID / MEMO' error={errorEmail} icon='icon-card-icon' onChange={(e) => setID(e)} />
      </div>

      <div className={styles.input}>
        <BaseInput
          error={errorPass}
          type={`${showPass ? 'text' : 'password'}`}
          onChange={(e) => setSecret(e)}
          placeholder='Card last 8 numbers' icon={`${showPass ? 'icon-eye-close' : 'icon-eye-open'}`}
          iconEvent={() => setShowPass(!showPass)} />
      </div>

      <div className='error-message m-2'>{errorApi}</div>

      <BaseButton title='Continuar' onClick={handleLogin} isLoading={isLoading} />

      <div className='extra-info'><Link to={'/home'} className='underline'>What's this?</Link> </div>

    </LayoutLogin>

  );
}

export default Login;