
type Object = {
  [k: string]: string
}
export const mapRoutes = (step: any) => {
  const stepMap: Object = {
    '1': '/verified-account',
    '2': '/register-phone',
    '3': '/validate-phone',
    '4': '/register-address',
    '5': '/kyc'
  }

  return stepMap[step] || '/my-card';
}