import { FC } from 'react'
import LayoutDefault from '../../adapters/layouts/default/index';
import BaseImage from '../../baseComponents/BaseImage';
import { getDepositQuote } from '../Auth/services/crypto.service';
import { useNavigate } from 'react-router-dom';
import { getStorage } from '../../storage';
import jwt_decode from "jwt-decode";
import { privateRoutes, publicRoutes } from '../../infra/routes/routes.path';
import { useQuery } from 'react-query';
import BaseButton from '../../baseComponents/BaseButton';
import styles from './styles/login.module.scss';

const HomePreLaunch: FC = () => {
  const navigate = useNavigate();
  const user = getStorage("userStorage");
  const { data, isLoading, isError, isFetched } = useQuery(`QuoteInfo`, () => getDepositQuote(10));

  const validateAuth = (user: any) => {
    if (!user?.token) {
      return false;
    }
    else {
      const decoded: any = jwt_decode(user.token);
      if (Date.now() >= decoded.exp * 1000) {
        return false;
      }
    }
    return true;
  };

  const handleCTA = () => {
    if (validateAuth(user)) {
      navigate(privateRoutes.MYCARD);
    }
    else {
      navigate(publicRoutes.LOGIN);
    }
  };

  return (
    <LayoutDefault>
      <div className='title mb-4 font-extra-big '>
        <span style={{ 'paddingTop': '10px', 'paddingLeft': '40px', 'paddingRight': '40px', 'display': 'inline-flex' }}>Hey, Dear Stellar Family Member!</span>
      </div>
      <div className='title mb-4 font-medium'>
        <strong style={{ 'maxWidth': '60%', 'display': 'inline-flex', 'marginTop': '20px', 'marginBottom': '20px' }}>Forget about finding a 'cave' to exchange your dollars at real market (street) price...</strong>
      </div>
      <div className='title mb-5 '>
        Anclap helps you to <br /><u>make the most out of your USDC 🤑</u>
        <br />by allowing you to exchange them <br />to ARS tokens, and then load your Anclap Card &#128525;!!!!!
        <div style={{ 'background': '#eee', 'padding': '20px', 'marginTop': '20px', 'marginBottom': '20px', 'borderRadius': '5px' }}>By using this Stellar powered card, <br /><strong>any tourist or foreign person can save lot of money</strong><br /> when spending here in Argentina.</div>

        <div className='title mb-5 '>

          <div style={{ 'background': '#fcfcfc', 'display': 'block', 'marginTop': '20px', 'marginBottom': '5px', 'borderRadius': '5px', 'paddingBottom': '20px' }}>
            <div style={{ 'display': 'inline-block', 'maxWidth': '80%', 'paddingTop': '20px' }}><b>How to use?</b> Easy....</div><br />
            <div style={{ 'display': 'inline-block', 'maxWidth': '80%', 'paddingTop': '5px' }}>Just send Stellar ARS or USDC tokens to an address including your memo, and that's it!</div><br />
            <div style={{ 'display': 'inline-block', 'maxWidth': '80%', 'paddingTop': '20px' }}><b>How much is it?</b>....</div><br />
            <div style={{ 'display': 'inline-block', 'maxWidth': '80%', 'paddingTop': '5px' }}>Free for the Stellar Family! 0%! 🤑</div><br />
            <div style={{ 'display': 'inline-block', 'maxWidth': '90%', 'paddingTop': '20px' }}><b>What FX are we using when sending USDC?</b></div><br />
            <div style={{ 'display': 'inline-block', 'maxWidth': '80%', 'paddingTop': '5px' }}>Stellar DEX exchange rate!<br /> <u>At this moment</u> the current exchange rate between USDC&lt;&gt;ARS is about:</div>
            <br />

            <div className='font-big' style={{ 'background': '#e1f9e4', 'display': 'inline-block', 'width': '250px', 'marginTop': '15px', 'padding': '8px', 'borderRadius': '5px' }}><b>10</b> <small>USDC</small> =&nbsp;
              {isLoading ? (<span className='subtitle'> finding path...</span>) :
                (<>
                  <span> <b>{data as string}</b> <small>ARS</small></span>
                </>)
              }
            </div>
            <br />
            <br />
            <BaseButton title='I WANT TO TRY!' onClick={handleCTA} cssClass={`${styles.ctaButton} btn-info`} />
          </div>
        </div>
      </div>
      <div style={{ 'display': 'block', 'maxWidth': '100%' }}><a href="mailto:info@anclap.com">info@anclap.com</a></div>
      <div className='title mt-2 font-regular'>
        <div className='title mt-1'>
          <a href="https://www.anclap.com"><BaseImage image='logo-anclap' height={40} /></a><br />
        </div>
        Connecting LATAM!!!
      </div>

    </LayoutDefault >
  );
}

export default HomePreLaunch;