import { FC, useState } from 'react'

import 'react-phone-input-2/lib/style.css'

import BaseButton from '../../../../../baseComponents/BaseButton';
import BaseIcon from '../../../../../baseComponents/BaseIcons';
import BaseInput from '../../../../../baseComponents/BaseInput/index';
import { destroyStorage, getStorage } from '../../../../../storage';
import { postResendMobileNumberValidationTokenService } from '../../../services/userAccount.service';
import { validateInput } from '../../../../../utilities/validations.utility';

import useApi from '../../../../../hooks/useApi.hook';

const ValidateMobileNumber: FC<{ handlerSubmit: any, isLoading: boolean, errorApi?: string }> = ({ handlerSubmit, isLoading = false, errorApi }) => {

  const [token, setToken] = useState('');
  const [tokenError, setTokenError] = useState('');
  const user = getStorage('userStorage');

  const validateSubmit = async () => {
    destroyStorage();
    const bTokenLen = validateInput(token, 6, null, null, setTokenError);
    if (bTokenLen) handlerSubmit(token);
  }

  const { isLoading: isLoadinResend, errorApi: errorResend, handleApi } = useApi();

  const resendMobileNumberValidationToken = () => {
    handleApi({}, postResendMobileNumberValidationTokenService)
  }

  return (
    <>
      <div className='justify-center mb-4'>
        <BaseIcon icon='icon-mobile' width={50} height={50} />
      </div>

      <div className='title mb-4'>Verifica tu número <br /> de teléfono</div>

      <div className='w-100' >
        <BaseInput maxLength={6} placeholder='Ingresa el código que recibiste por SMS' onChange={(e) => setToken(e)} error={tokenError} />
      </div>

      <div className='error-message m-2'>{errorApi || errorResend}</div>

      <BaseButton title='Continuar' onClick={() => validateSubmit()} isLoading={isLoading || isLoadinResend} />

      <div className='subtitle mt-4'>
        <small>El código fué enviado a {user?.id}</small>
      </div>

      <div className='extra-info cursor-pointer' onClick={() => resendMobileNumberValidationToken()}>Reenviar SMS de verificación</div>
    </ >

  );
}

export default ValidateMobileNumber;