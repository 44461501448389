import LogoAnclapW from "./logo-anclap-w.svg";
import LogoAnclap from "./logo-anclap.svg";
import IconEmail from "./icon-email.svg";
import IconMobile from "./icon-mobile.svg";
import IconEmailBlue from "./icon-email-blue.svg";
import IconShowPassword from "./icon-show-password.svg";
import IconHidePassword from "./icon-hide-password.svg";
import IconSuccess from "./icon-success.svg";
import IconUSDC from "./icon-usdc.svg";
import IconARS from "./ars.png";
import IconUSDT from "./icon-usdt.svg";
import IconDAI from "./icon-dai.svg";
import IconCopy from "./icon-copy.svg";
import IconClose from "./icon-close.svg";
import IconLogOut from "./icon-logout.svg";
import IconShow from "./icon-eye-open.svg";
import IconHide from "./icon-eye-close.svg";
import IconCardIcon from "./icon-card-icon.svg";
import IconKineticQR from "./qr-kinetic.svg";

import IconMovStatusFail from "./mov-status-fail.svg";
import IconMovLoad from "./mov-load2.svg";
import IconMovUnload from "./mov-unload2.svg";
import IconMovExpense from "./mov-expense.svg";
import IconMovOther from "./mov-other.svg";
import IconMovCash from "./mov-cash.svg";

type Map = {
  [key: string]: string | undefined
}

const SvgImage: Map = {
  "default": "",
  "icon-logout": IconLogOut,
  "logo-anclap-w": LogoAnclapW,
  "logo-anclap": LogoAnclap,
  "icon-email": IconEmail,
  "icon-mobile": IconMobile,
  "icon-email-blue": IconEmailBlue,
  "icon-show-password": IconShowPassword,
  "icon-hide-password": IconHidePassword,
  "icon-success": IconSuccess,
  "icon-usdt": IconUSDT,
  "icon-ars": IconARS,
  "icon-usdc": IconUSDC,
  "icon-dai": IconDAI,
  "icon-copy": IconCopy,
  "icon-close": IconClose,
  "icon-eye-open": IconShow,
  "icon-eye-close": IconHide,
  "icon-card-icon": IconCardIcon,
  "icon-qr-kinetic": IconKineticQR,

  "mov-status-fail": IconMovStatusFail,
  "mov-load": IconMovLoad,
  "mov-unload": IconMovUnload,
  "mov-expense": IconMovExpense,
  "mov-other": IconMovOther,
  'mov-cash': IconMovCash,

};

export default SvgImage;

