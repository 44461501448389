import { FC, useEffect, useMemo, useState } from 'react'
import LayoutDefault from '../../../../adapters/layouts/default/index';
import BaseInput from '../../../../baseComponents/BaseInput/index';

import countryList from 'react-select-country-list';
import BaseSelect from '../../../../baseComponents/BaseSelect';
import BaseButton from '../../../../baseComponents/BaseButton';
import { registerAddressService } from '../../services/userAccount.service';
import AddressSuccess from './components/AdressSuccess';
import useApi from '../../../../hooks/useApi.hook';

const RegisterAddress: FC = () => {
  const options = useMemo(() => countryList().getData(), []);
  const [errorComplete, setErrorComplete] = useState('');
  const [countryCode, setCountryCode] = useState('CO');
  const [stateName, setStateName] = useState('');
  const [city, setCity] = useState('');
  const [zipCode, setZipCode] = useState('');
  const [addressLine1, setAddressLine1] = useState('');
  const [addressLine2, setAddressLine2] = useState('');
  const [isSuccess, setIsSuccess] = useState(false);

  const { data, isLoading, errorApi, handleApi } = useApi();

  const handlerSubmit = async () => {
    const payload = {
      countryCode,
      state: stateName,
      city,
      zipCode,
      addressLine1,
      addressLine2
    }

    if(!countryCode || !stateName || !city || !zipCode || !addressLine1 )
      setErrorComplete("Completa todos los campos obligatorios");

    handleApi(payload, registerAddressService);
  }

  if (data) {
    return (<AddressSuccess />)
  }

  return (
    <LayoutDefault>
      <div className='title mb-1'>Necesitamos tu dirección</div>
      <div className='subtitle mb-4'>Completa los siguientes campos</div>

      <div className='w-100'>
        <BaseSelect options={options} onChange={setCountryCode}  />
      </div>

      <div className='w-100'>
        <BaseInput placeholder='Provincia / Estado' onChange={(e) => setStateName(e)} />
      </div>

      <div className='w-100'>
        <BaseInput placeholder='Ciudad' onChange={(e) => setCity(e)} />
      </div>

      <div className='w-100'>
        <BaseInput placeholder='Código Postal' onChange={(e) => setZipCode(e)} />
      </div>

      <div className='w-100'>
        <BaseInput placeholder='Dirección (Linea 1)' onChange={(e) => setAddressLine1(e)} />
      </div>

      <div className='w-100'>
        <BaseInput placeholder='Dirección (Linea 2)' onChange={(e) => setAddressLine2(e)} />
      </div>
      <>
        <div className='error-message m-2'>{errorApi}</div>
      </>
      <BaseButton title='Continuar' onClick={() => handlerSubmit()} isLoading={isLoading} />

    </LayoutDefault>

  );
}

export default RegisterAddress;